exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-downloads-tsx": () => import("./../../../src/pages/downloads.tsx" /* webpackChunkName: "component---src-pages-downloads-tsx" */),
  "component---src-pages-eula-tsx": () => import("./../../../src/pages/eula.tsx" /* webpackChunkName: "component---src-pages-eula-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-payment-canceled-tsx": () => import("./../../../src/pages/payment/canceled.tsx" /* webpackChunkName: "component---src-pages-payment-canceled-tsx" */),
  "component---src-pages-payment-failed-tsx": () => import("./../../../src/pages/payment/failed.tsx" /* webpackChunkName: "component---src-pages-payment-failed-tsx" */),
  "component---src-pages-payment-success-tsx": () => import("./../../../src/pages/payment/success.tsx" /* webpackChunkName: "component---src-pages-payment-success-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-registration-tsx": () => import("./../../../src/pages/registration.tsx" /* webpackChunkName: "component---src-pages-registration-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */)
}

