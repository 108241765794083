module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"mobile":"(min-width: 320px)","mobileM":"(min-width: 360px)","mobileL":"(min-width: 568px)","tablet":"(min-width: 768px)","tabletL":"(min-width: 1024px)","laptopS":"(min-width: 1140px)","laptop":"(min-width: 1366px)","desktop":"(min-width: 1680px)","xxl":"(min-width: 1920px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"minify":false,"transpileTemplateLiterals":false,"pure":true,"displayName":true,"fileName":true,"namespace":"","topLevelImportPaths":[],"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/assets/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"617785e795cd3ecf5ec9ba30f618ebcb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"i18n","languages":["en","ru","cn"],"defaultLanguage":"ru","fallbackLanguage":"en","generateDefaultLanguagePage":true,"redirect":true,"siteUrl":"https://surfinite.com","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
